import { z } from "zod";
import { EffectField, EffectFilterCurrencyField, EffectFilterDateField, GlobalCalculationIdentifier } from "../constants";
import { zNumericId } from "./baseschemas";

export const zSpanEffectDto = z.object({
    id: zNumericId,
    effectCategoryId: zNumericId,

    // has initial
    prePlanHasInitial: z.boolean(),
    planHasInitial: z.boolean(),
    postPlanHasInitial: z.boolean(),
    preForecastHasInitial: z.boolean(),
    forecastHasInitial: z.boolean(),
    postForecastHasInitial: z.boolean(),
    preActualHasInitial: z.boolean(),
    actualHasInitial: z.boolean(),
    postActualHasInitial: z.boolean(),

    // dates
    prePlanStartDate: z.string().nullable(),
    planStartDate: z.string().nullable(),
    postPlanStartDate: z.string().nullable(),
    preForecastStartDate: z.string().nullable(),
    forecastStartDate: z.string().nullable(),
    postForecastStartDate: z.string().nullable(),
    preActualStartDate: z.string().nullable(),
    actualStartDate: z.string().nullable(),
    postActualStartDate: z.string().nullable(),

    prePlanEndDate: z.string().nullable(),
    planEndDate: z.string().nullable(),
    postPlanEndDate: z.string().nullable(),
    preForecastEndDate: z.string().nullable(),
    forecastEndDate: z.string().nullable(),
    postForecastEndDate: z.string().nullable(),
    preActualEndDate: z.string().nullable(),
    actualEndDate: z.string().nullable(),
    postActualEndDate: z.string().nullable(),

    // initial
    prePlanInputInitial: z.number().nullable(),
    planInputInitial: z.number().nullable(),
    postPlanInputInitial: z.number().nullable(),
    preForecastInputInitial: z.number().nullable(),
    forecastInputInitial: z.number().nullable(),
    postForecastInputInitial: z.number().nullable(),
    preActualInputInitial: z.number().nullable(),
    actualInputInitial: z.number().nullable(),
    postActualInputInitial: z.number().nullable(),

    prePlanInputInitialFormula: z.string().nullable(),
    planInputInitialFormula: z.string().nullable(),
    postPlanInputInitialFormula: z.string().nullable(),
    preForecastInputInitialFormula: z.string().nullable(),
    forecastInputInitialFormula: z.string().nullable(),
    postForecastInputInitialFormula: z.string().nullable(),
    preActualInputInitialFormula: z.string().nullable(),
    actualInputInitialFormula: z.string().nullable(),
    postActualInputInitialFormula: z.string().nullable(),

    // target
    prePlanInputTarget: z.number().nullable(),
    planInputTarget: z.number().nullable(),
    postPlanInputTarget: z.number().nullable(),
    preForecastInputTarget: z.number().nullable(),
    forecastInputTarget: z.number().nullable(),
    postForecastInputTarget: z.number().nullable(),
    preActualInputTarget: z.number().nullable(),
    actualInputTarget: z.number().nullable(),
    postActualInputTarget: z.number().nullable(),

    prePlanInputTargetFormula: z.string().nullable(),
    planInputTargetFormula: z.string().nullable(),
    postPlanInputTargetFormula: z.string().nullable(),
    preForecastInputTargetFormula: z.string().nullable(),
    forecastInputTargetFormula: z.string().nullable(),
    postForecastInputTargetFormula: z.string().nullable(),
    preActualInputTargetFormula: z.string().nullable(),
    actualInputTargetFormula: z.string().nullable(),
    postActualInputTargetFormula: z.string().nullable(),

    // effect
    prePlanInputEffect: z.number().nullable(),
    planInputEffect: z.number().nullable(),
    postPlanInputEffect: z.number().nullable(),
    preForecastInputEffect: z.number().nullable(),
    forecastInputEffect: z.number().nullable(),
    postForecastInputEffect: z.number().nullable(),
    preActualInputEffect: z.number().nullable(),
    actualInputEffect: z.number().nullable(),
    postActualInputEffect: z.number().nullable(),

    prePlanInputEffectFormula: z.string().nullable(),
    planInputEffectFormula: z.string().nullable(),
    postPlanInputEffectFormula: z.string().nullable(),
    preForecastInputEffectFormula: z.string().nullable(),
    forecastInputEffectFormula: z.string().nullable(),
    postForecastInputEffectFormula: z.string().nullable(),
    preActualInputEffectFormula: z.string().nullable(),
    actualInputEffectFormula: z.string().nullable(),
    postActualInputEffectFormula: z.string().nullable(),

    // price hike
    prePlanInputPriceHike: z.number().nullable(),
    planInputPriceHike: z.number().nullable(),
    postPlanInputPriceHike: z.number().nullable(),
    preForecastInputPriceHike: z.number().nullable(),
    forecastInputPriceHike: z.number().nullable(),
    postForecastInputPriceHike: z.number().nullable(),
    preActualInputPriceHike: z.number().nullable(),
    actualInputPriceHike: z.number().nullable(),
    postActualInputPriceHike: z.number().nullable(),

    prePlanInputPriceHikeFormula: z.string().nullable(),
    planInputPriceHikeFormula: z.string().nullable(),
    postPlanInputPriceHikeFormula: z.string().nullable(),
    preForecastInputPriceHikeFormula: z.string().nullable(),
    forecastInputPriceHikeFormula: z.string().nullable(),
    postForecastInputPriceHikeFormula: z.string().nullable(),
    preActualInputPriceHikeFormula: z.string().nullable(),
    actualInputPriceHikeFormula: z.string().nullable(),
    postActualInputPriceHikeFormula: z.string().nullable(),
});

export type SpanEffectDto = z.infer<typeof zSpanEffectDto>;

export const zSpanEffectListDto = z.array(zSpanEffectDto);

export type SpanEffectListDto = z.infer<typeof zSpanEffectListDto>;

export const zSpanEffectUpdateDto = z.object({
    calculationIdentifier: z.nativeEnum(GlobalCalculationIdentifier),
    [EffectField.HasInitial]: z.boolean().optional(),
    [EffectFilterDateField.StartDate]: z.string().nullish(),
    [EffectFilterDateField.EndDate]: z.string().nullish(),
    [EffectFilterCurrencyField.Effect]: z.string().or(z.number()).nullish(),
    [EffectFilterCurrencyField.Initial]: z.string().or(z.number()).nullish(),
    [EffectFilterCurrencyField.Target]: z.string().or(z.number()).nullish(),
    [EffectFilterCurrencyField.PriceHike]: z.string().or(z.number()).nullish(),
    [EffectFilterDateField.EndOfRecurrence]: z.string().nullish(),
});

export type SpanEffectUpdateDto = z.infer<typeof zSpanEffectUpdateDto>;
