import { z } from "zod";
import { AclPermissions, ClientGroupType, DEFAULT_POTENTIAL_INTERVALS, FeatureFlags, UserTier } from "../constants";
import { zHexColor, zNumericId } from "./baseschemas";
import { zCurrencyDto } from "./currencies";
import { zDomainDto } from "./domains";

export const zClientGroupDto = z.object({
    groupId: zNumericId,
    type: z.nativeEnum(ClientGroupType),
});

export const zClientDto = z.object({
    id: zNumericId,
    name: z.string(),
    alias: z.string(),
    isCustomer: z.number(),
    adminUserSeats: z.number().nullable(),
    advancedUserSeats: z.number().nullable(),
    basicUserSeats: z.number().nullable(),
    lightUserSeats: z.number().nullable(),
    duns: z.number().nullable(),
    timezone: z.string(),
    createdAt: z.date().transform((d) => d.toJSON()),
    updatedAt: z.date().transform((d) => d.toJSON()),
    features: z.record(z.nativeEnum(FeatureFlags), z.number()),
    fiscalYear: z.number(),
    fiscalYearRangePast: z.number(),
    fiscalYearRangeFuture: z.number(),
    currencies: z.array(zCurrencyDto),
    domains: z.array(zDomainDto).optional(),
    logoHash: z.string().nullable(),
    internalContactId: zNumericId.nullable(),
    // Use default value to avoid rewriting all the types in superadmin components
    ideaPotentialIntervals: z
        .array(z.number())
        .nullable()
        .transform((x) => x ?? DEFAULT_POTENTIAL_INTERVALS),
    clientGroups: z.array(zClientGroupDto),
    defaultUserTier: z.nativeEnum(UserTier),
    externalUserTier: z.nativeEnum(UserTier),
    defaultMeasurePermission: z.nativeEnum(AclPermissions),
    dashboardColors: z.array(zHexColor).min(12).nullable(),
    whiteSpotColor: zHexColor,
    endOfRecurrence: z.string().nullish(),
});

export type ClientGroupDto = z.infer<typeof zClientGroupDto>;
export type ClientDto = z.infer<typeof zClientDto>;
