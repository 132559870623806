import { z } from "zod";
import { CalculationEffectType, EffectFilterCurrencyField, GlobalCalculationIdentifier, MaxYear, MinYear } from "../constants";
import { zNumericId } from "./baseschemas";

export const zCalculationEffectDto = z.object({
    month: z.number().int().min(0).max(11),
    year: z
        .number()
        .int()
        .min(MinYear)
        .max(MaxYear - 1),
    measureId: zNumericId,
    effectCategoryId: zNumericId,

    prePlanInputInitial: z.number().nullable(),
    planInputInitial: z.number().nullable(),
    postPlanInputInitial: z.number().nullable(),
    preForecastInputInitial: z.number().nullable(),
    forecastInputInitial: z.number().nullable(),
    postForecastInputInitial: z.number().nullable(),
    preActualInputInitial: z.number().nullable(),
    actualInputInitial: z.number().nullable(),
    postActualInputInitial: z.number().nullable(),
    latestInputInitial: z.number().nullable(),

    prePlanInitial: z.number().nullable(),
    planInitial: z.number().nullable(),
    postPlanInitial: z.number().nullable(),
    preForecastInitial: z.number().nullable(),
    forecastInitial: z.number().nullable(),
    postForecastInitial: z.number().nullable(),
    preActualInitial: z.number().nullable(),
    actualInitial: z.number().nullable(),
    postActualInitial: z.number().nullable(),
    latestInitial: z.number().nullable(),

    prePlanInputTarget: z.number().nullable(),
    planInputTarget: z.number().nullable(),
    postPlanInputTarget: z.number().nullable(),
    preForecastInputTarget: z.number().nullable(),
    forecastInputTarget: z.number().nullable(),
    postForecastInputTarget: z.number().nullable(),
    preActualInputTarget: z.number().nullable(),
    actualInputTarget: z.number().nullable(),
    postActualInputTarget: z.number().nullable(),
    latestInputTarget: z.number().nullable(),

    prePlanTarget: z.number().nullable(),
    planTarget: z.number().nullable(),
    postPlanTarget: z.number().nullable(),
    preForecastTarget: z.number().nullable(),
    forecastTarget: z.number().nullable(),
    postForecastTarget: z.number().nullable(),
    preActualTarget: z.number().nullable(),
    actualTarget: z.number().nullable(),
    postActualTarget: z.number().nullable(),
    latestTarget: z.number().nullable(),

    prePlanInputEffect: z.number().nullable(),
    planInputEffect: z.number().nullable(),
    postPlanInputEffect: z.number().nullable(),
    preForecastInputEffect: z.number().nullable(),
    forecastInputEffect: z.number().nullable(),
    postForecastInputEffect: z.number().nullable(),
    preActualInputEffect: z.number().nullable(),
    actualInputEffect: z.number().nullable(),
    postActualInputEffect: z.number().nullable(),
    latestInputEffect: z.number().nullable(),

    prePlanEffect: z.number().nullable(),
    planEffect: z.number().nullable(),
    postPlanEffect: z.number().nullable(),
    preForecastEffect: z.number().nullable(),
    forecastEffect: z.number().nullable(),
    postForecastEffect: z.number().nullable(),
    preActualEffect: z.number().nullable(),
    actualEffect: z.number().nullable(),
    postActualEffect: z.number().nullable(),
    latestEffect: z.number().nullable(),

    prePlanInputPriceHike: z.number().nullable(),
    planInputPriceHike: z.number().nullable(),
    postPlanInputPriceHike: z.number().nullable(),
    preForecastInputPriceHike: z.number().nullable(),
    forecastInputPriceHike: z.number().nullable(),
    postForecastInputPriceHike: z.number().nullable(),
    preActualInputPriceHike: z.number().nullable(),
    actualInputPriceHike: z.number().nullable(),
    postActualInputPriceHike: z.number().nullable(),
    latestInputPriceHike: z.number().nullable(),

    prePlanPriceHike: z.number().nullable(),
    planPriceHike: z.number().nullable(),
    postPlanPriceHike: z.number().nullable(),
    preForecastPriceHike: z.number().nullable(),
    forecastPriceHike: z.number().nullable(),
    postForecastPriceHike: z.number().nullable(),
    preActualPriceHike: z.number().nullable(),
    actualPriceHike: z.number().nullable(),
    postActualPriceHike: z.number().nullable(),
    latestPriceHike: z.number().nullable(),

    prePlanEffectType: z.nativeEnum(CalculationEffectType).nullable(),
    planEffectType: z.nativeEnum(CalculationEffectType).nullable(),
    postPlanEffectType: z.nativeEnum(CalculationEffectType).nullable(),
    preForecastEffectType: z.nativeEnum(CalculationEffectType).nullable(),
    forecastEffectType: z.nativeEnum(CalculationEffectType).nullable(),
    postForecastEffectType: z.nativeEnum(CalculationEffectType).nullable(),
    preActualEffectType: z.nativeEnum(CalculationEffectType).nullable(),
    actualEffectType: z.nativeEnum(CalculationEffectType).nullable(),
    postActualEffectType: z.nativeEnum(CalculationEffectType).nullable(),
    latestEffectType: z.nativeEnum(CalculationEffectType).nullable(),
});
export type CalculationEffectDto = z.infer<typeof zCalculationEffectDto>;

export const zCalculationEffectListDto = z.array(zCalculationEffectDto);

export type CalculationEffectListDto = z.infer<typeof zCalculationEffectListDto>;

export const zEffectUpdateDto = z.object({
    effectCategoryId: zNumericId,
    calculationIdentifier: z.nativeEnum(GlobalCalculationIdentifier),
    type: z.nativeEnum(EffectFilterCurrencyField),
    month: z.number().int().min(0).max(11),
    year: z
        .number()
        .int()
        .min(MinYear)
        .max(MaxYear - 1),
    duration: z.number().int().min(1).max(12),
    value: z.number(),
});

export type EffectUpdateDto = z.infer<typeof zEffectUpdateDto>;

export const zEffectDeleteDto = z.object({
    effectCategoryId: zNumericId,
    calculationIdentifier: z.nativeEnum(GlobalCalculationIdentifier),
    type: z.nativeEnum(EffectFilterCurrencyField),
    month: z.number().int().min(0).max(11).optional(),
    year: z
        .number()
        .int()
        .min(MinYear)
        .max(MaxYear - 1)
        .optional(),
    duration: z.number().int().min(1).max(12).optional(),
});

export type EffectDeleteDto = z.infer<typeof zEffectDeleteDto>;

export const zEffectDate = z.object({
    year: z.number(),
    month: z.number(),
});

export const zFiscalYearValue = z.object({ year: z.string(), value: z.number() });

export type EffectDate = z.infer<typeof zEffectDate>;

export const zEffectTotalValues = z.object({
    startDate: zEffectDate.nullable(),
    endDate: zEffectDate.nullable(),
    initial: z.number().nullable(),
    target: z.number().nullable(),
    effect: z.number().nullable(),
    priceHike: z.number().nullable(),
    costAvoidance: z.number().nullable(),
    effectPerFiscalYear: zFiscalYearValue.array(),
});

export type FiscalYearValue = z.infer<typeof zFiscalYearValue>;
export type EffectTotalValues = z.infer<typeof zEffectTotalValues>;
