export enum WidgetType {
    ProcessList = "processList",
    CompletedEffects = "completedEffects",
    ActivityList = "activityList",
    CustomBarChart = "customBarChart",
    FunnelChart = "funnelChart",
    RollingForecast = "rollingForecast",
    IdeaList = "ideaList",
    IdeaMatrix = "ideaMatrix",
    ProcessWhiteSpotMatrix = "processWhiteSpotMatrix",
    CommentStream = "commentStream",
    LiveRunUp = "liveRunUp",
    PotentialKPI = "potentialKPI",
    StatusAggregation = "statusAggregation",
    LegacyProjectProgress = "legacyProjectProgress",
    Timeline = "timeline",
    WeeklySavingsRunUp = "weeklySavingsRunUp",
    Waterfall = "waterfall",
    ProjectProgress = "projectProgress",
}

export enum WidgetConfigTemplateString {
    DecisionGateTaskConfigIds = "$decisionGateTaskConfigIds$",
    ForecastProbabilities = "$forecastProbabilities$",
    MyUserId = "$myUserId$",
    CurrentFiscalYearStart = "$currentFiscalYearStart$",
    CurrentFiscalYearEnd = "$currentFiscalYearEnd$",
    MeasureMeasureConfigId = "$measureMeasureConfigId$",
    CurrentFiscalYear = "$currentFiscalYear$",
    CurrentFiscalYearStartMonth = "$currentFiscalYearStartMonth$",
    CurrentFiscalYearEndMonth = "$currentFiscalYearEndMonth$",
}

export enum AggregationMethod {
    Count = "count",
    Sum = "sum",
}

export enum PivotMetric {
    Effect = "effect",
    CumulatedEffect = "cumulatedEffect",
    RecurringEffect = "recurringEffect",
    MeasureId = "measureId",
}

export enum ChartLayout {
    Vertical = "vertical",
    Horizontal = "horizontal",
}

export enum TooltipLayout {
    Normal = "normal",
    Reversed = "reversed",
}

export enum LegendLayout {
    Normal = "normal",
    Reversed = "reversed",
}

export enum RollingForecastDataRepresentation {
    None = "none",
    Bar = "bar",
    Line = "line",
}

export enum WaterfallSign {
    Plus = "plus",
    Minus = "minus",
}

export const WIDGET_PAGE_SIZE_OPTIONS = [25, 50, 100];
