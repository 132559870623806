// mathjs/number is a more lightweight module, that does not contain BigInt/Complex/... data types
// This reduces imported mathjs bundle
import { compile, round } from "mathjs/number";
import { validateCurrencyValue } from "./validateCurrencyValue";

// Redefine mathJs EvalFunction type, because using it makes TypeScript complain about ESM/CJS issues
// Looks like mathJS pretends to be ESM, but actually is CJS
export type EvalFunction = (scope?: Record<string, number>) => number | null;
export function compileFormula(value: string): EvalFunction | null {
    try {
        const compiled = compile(value);
        return (scope) => {
            try {
                const result = compiled.evaluate(scope);
                if (Number.isNaN(result) || !Number.isFinite(result)) {
                    return null;
                }
                return result;
            } catch {
                // runtime evaluation errors against given scope
                return null;
            }
        };
    } catch {
        // compile errors
        return null;
    }
}

export function validateFormula(formula: string): boolean {
    try {
        compile(formula);
    } catch {
        return false;
    }
    return true;
}

export function evaluateFormula(formula: string, scope: Record<string, number> = {}): number | null {
    let result;
    try {
        result = compile(formula).evaluate(scope);
    } catch {
        return null;
    }

    if (Number.isNaN(result) || !Number.isFinite(result)) {
        return null;
    }
    return result;
}

export function evaluateCurrencyFormula(formula: string, scope: Record<string, number> = {}): number | null {
    if (!formula.startsWith("=")) {
        throw new Error("Parameter is not a valid formula");
    }

    if (formula.length === 1) {
        return null;
    }

    const result = evaluateFormula(formula.substring(1), scope);

    if (result === null) {
        return null;
    }

    let rounded;
    try {
        rounded = round(result, 4);
    } catch {
        return null;
    }

    if (!validateCurrencyValue(rounded)) {
        return null;
    }

    return rounded;
}

// see: https://mathjs.org/docs/datatypes/numbers.html#equality
const EPSILON = 1e-12;
const DBL_EPSILON = Number.EPSILON;
export function nearlyEqual(a: number, b: number, precision = EPSILON): boolean {
    if (a === b) {
        return true;
    }

    const diff = Math.abs(a - b);
    return diff <= Math.max(Math.abs(a), Math.abs(b)) * precision || diff < DBL_EPSILON;
}
