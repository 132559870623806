/**
 * ATTENTION! Only add new error constants at the end of the enum!
 * DO NOT DELETE constants!
 */
export enum ErrorConstantKeys {
    VDERROR_UNKNOWN,
    VDERROR_UNAUTHORIZED_TOKEN,
    VDERROR_UNAUTHORIZED_RESET_TOKEN,
    VDERROR_UNAUTHORIZED_PASSWORD,
    VDERROR_UNAUTHORIZED_SUPER_ADMIN,
    VDERROR_NOT_FOUND_MEASURE,
    VDERROR_NOT_FOUND_COMMENT,
    VDERROR_NOT_FOUND_COMPANY,
    VDERROR_NOT_FOUND_FILTER,
    VDERROR_NOT_FOUND_GATETASK,
    VDERROR_NOT_FOUND_CLIENT,
    VDERROR_NOT_FOUND_USER,
    VDERROR_NOT_FOUND_SUBTASK,
    VDERROR_NOT_FOUND_GATETASK_FOR_MEASURE,
    VDERROR_NOT_FOUND_ADDRESS,
    VDERROR_NOT_FOUND_SUPPLIER,
    VDERROR_NOT_FOUND_MEASURE_USER,
    VDERROR_NOT_FOUND_DASHBOARD_MEASURES,
    VDERROR_NOT_FOUND_MEASURE_FOR_TOKEN,
    VDERROR_NOT_FOUND_FILE,
    VDERROR_NOT_FOUND_TRANSLATION,
    VDERROR_NOT_FOUND_COST_LEVER,
    VDERROR_NOT_FOUND_UISTATE,
    VDERROR_DO_NO_USE_THIS_CONSTANT, // This was duplicate, but cannot be removed to avoid shifting of constant values
    VDERROR_INVALID_MEASURE_TITLE,
    VDERROR_INVALID_TABLENAME,
    VDERROR_INVALID_GATETASK,
    VDERROR_INVALID_TOKEN,
    VDERROR_INVALID_VIEWTYPE,
    VDERROR_INVALID_VISIBILITY, // Obsolete
    VDERROR_INVALID_PASSWORD_TOKEN,
    VDERROR_INVALID_DECIDER,
    VDERROR_INVALID_DECISION_SELECTION,
    VDERROR_INVALID_OPERATION_FOR_USER_STATUS,
    VDERROR_INVALID_SORT_COLUMN,
    VDERROR_UNDEFINED_BUCKET,
    VDERROR_UNDEFINED_ACCESS_KEY_ID,
    VDERROR_UNDEFINED_SECRET_ACCESS_KEY,
    VDERROR_UNDEFINED_FOLDER,
    VDERROR_UNDEFINED_CLIENT_CLIENT,
    VDERROR_UNDEFINED_MEASURE,
    VDERROR_UNDEFINED_INTERNAL_ID,
    VDERROR_FORBIDDEN_ACCESS,
    VDERROR_CONFLICT_DECISION_ALREADY_COMPLETED,
    VDERROR_FORBIDDEN_ACCESS_MODIFY_COMMENT,
    VDERROR_FORBIDDEN_MODIFY_SEARCH,
    VDERROR_FORBIDDEN_ACCESS_AUTHUSER,
    VDERROR_FORBIDDEN_DELETION_FILTER,
    VDERROR_FORBIDDEN_MODIFY_FILTER,
    VDERROR_FORBIDDEN_ACCESS_SET_USER,
    VDERROR_FORBIDDEN_ACCESS_ADD_USER,
    VDERROR_FORBIDDEN_ACCESS_TO_ASSIGN,
    VDERROR_FORBIDDEN_ACCESS_MODIFY_PERMISSIONS,
    VDERROR_FORBIDDEN_ACCESS_MODIFY_NOTIFICATIONS,
    VDERROR_FORBIDDEN_ACCESS_RESET_PASSWORD,
    VDERROR_FORBIDDEN_ACCESS_ADMIN,
    VDERROR_FORBIDDEN_ACCESS_PERMISSION_REQUIRED,
    VDERROR_FORBIDDEN_ACCESS_CLIENT_ID,
    VDERROR_CONFLICT_EMAIL,
    VDERROR_CONFLICT_DOMAINS_FOUND,
    VDERROR_CONFLICT_FILTER,
    VDERROR_CONFLICT_PREDECESSOR_GATETASK,
    VDERROR_CONFLICT_EFFECT_CATEGORY_EXIST,
    VDERROR_CONFLICT_MEASURE_USER_EXISTS,
    VDERROR_CONFLICT_GATETASK,
    VDERROR_CONFLICT_REMOVE_COMPANY_IN_USE,
    VDERROR_CONFLICT_COMPANY_MIN_ADDRESS,
    VDERROR_BAD_REQUEST,
    VDERROR_BAD_CONTENT,
    VDERROR_BAD_MEASURE,
    VDERROR_BAD_FILTER_DEFINITION,
    VDERROR_BAD_FILTER_BY_PERSON,
    VDERROR_BAD_GATETASK,
    VDERROR_BAD_COMPLETED_GATETASK,
    VDERROR_BAD_USER,
    VDERROR_BAD_USER_STATUS,
    VDERROR_BAD_PASSWORD,
    VDERROR_BAD_CURRENCY_FORMAT,
    VDERROR_BAD_TIME_RANGE,
    VDERROR_BAD_ORDER,
    VDERROR_BAD_PROPERTY,
    VDERROR_BAD_EMAIL,
    VDERROR_BAD_COMMENT_LENGTH,
    VDERROR_BAD_AUTHENTICATION,
    VDERROR_BAD_AUTHENTICATION_NO_MORE_USERS,
    VDERROR_BAD_ACCEPT_CONTACT,
    VDERROR_BAD_ACCEPT_DATA_SAVING, // Obsolete
    VDERROR_BAD_ACCEPT_DATA_USAGE, // Obsolete
    VDERROR_BAD_THUMBNAIL,
    VDERROR_BAD_MEASURE_IDS,
    VDERROR_BAD_PERMISSIONS,
    VDERROR_BAD_ADMIN_REQUEST_TYPE,
    VDERROR_BAD_COST_LEVER_SEARCH,
    VDERROR_REMOVE_FAILED,
    VDERROR_DO_NOT_BITE_THE_HAND_THAT_FEEDS_YOU,
    VDERROR_INTERNAL_SERVER,
    VDERROR_DO_NOT_DELETE_SUPER_ADMIN,
    VDERROR_NOT_FOUND_ACTIVITY_TEMPLATE,
    VDERROR_FORBIDDEN_FEATURE_NOT_ENABLED,
    VDERROR_FORBIDDEN_FILE_UPLOAD,
    VDERROR_FORBIDDEN_FILE_DELETE,
    VDERROR_NOT_FOUND_DOMAIN,
    VDERROR_FORBIDDEN_MODIFY_DOMAIN,
    VDERROR_INVALID_INVITE_TOKEN,
    VDERROR_UNAUTHORIZED_INVITE_TOKEN,
    VDERROR_BAD_REQUEST_DOMAINS,
    VDERROR_BAD_REQUEST_REMINDER_DATE,
    VDERROR_INTERNAL_MEASURE_NOT_CREATED,
    VDERROR_INVALID_USER,
    VDERROR_INTERNAL_RELATION_NOT_LOADED,
    VDERROR_UNAUTHORIZED_CLIENT_IS_NOT_A_CUSTOMER,
    VDERROR_BAD_DATERANGE,
    VDERROR_NOT_FOUND_MEASURE_CONFIG,
    VDERROR_BAD_MEASURE_CONFIGURATION,
    VDERROR_NOT_FOUND_DECISION,
    VDERROR_NOT_FOUND_GATE_TASK_CONFIG,
    VDERROR_PARSING_FAILED,
    VDERROR_NOT_FOUND_EFFECT_CATEGORY,
    VDERROR_NOT_FOUND_CALCULATION_IDENTIFIER,
    VDERROR_INTERNAL_INCONSISTENT_STATE,
    VDERROR_BAD_REQUEST_NEW_MEASURE_ASSIGNEE_NEEDED,
    VDERROR_USER_PROFILE_ERROR,
    VDERROR_DOMAIN_IS_IN_USE,
    VDERROR_BAD_REQUEST_DOMAIN_ALREADY_EXISTS,
    VDERROR_IDENTITY_PROVIDER_IS_IN_USE,
    VDERROR_NOT_FOUND_IDENTITY_PROVIDER,
    VDERROR_MISSING_CLIENT_CURRENCY,
    VDERROR_NOT_FOUND_CURRENCY,
    VDERROR_BAD_REQUEST_CURRENCY_IN_USE,
    VDERROR_CONFLICT_CURRENCY_ALREADY_EXISTS,
    VDERROR_BAD_REQUEST_MISSING_EXCHANGE_RATES,
    VDERROR_INVALID_ATTRIBUTE_TITLE,
    VDERROR_CANNOT_DELETE_ATTRIBUTE,
    VDERROR_NOT_FOUND_ATTRIBUTE,
    VDERROR_ATTRIBUTE_IS_IN_USE,
    VDERROR_NOT_FOUND_DEFAULT_SEARCH_CONFIG,
    VDERROR_NOT_FOUND_CUSTOMVALUE,
    VDERROR_NOT_FOUND_SEARCH_CONFIG,
    VDERROR_BAD_REQUEST_NOT_ALLOWED_TO_RENAME_DEFAULT_SEARCH_CONFIG,
    VDERROR_BAD_REQUEST_CANNOT_DELETE_DEFAULT_SEARCH_CONFIG,
    VDERROR_NOT_FOUND_WIDGET,
    VDERROR_NOT_FOUND_DASHBOARD,
    VDEEROR_NOT_FOUND_WIDGET_TEMPLATE,
    VDERROR_CUSTOMVALUE_IN_USE,
    VDERROR_BAD_REQUEST_INVALID_WIDGET_CONFIG,
    VDERROR_BAD_REQUEST_INVALID_DASHBOARD_LAYOUT,
    VDERROR_INTERNAL_ENV_NOT_SET,
    VDERROR_BAD_REQUEST_NO_MORE_COMPANIES,
    VDERROR_BAD_REQUEST_NO_MORE_USERS,
    VDERROR_CONFLICT,
    VDERROR_UNAUTHORIZED_CONTEXT,
    VDERROR_NOT_FOUND_IDEA,
    VDERROR_MEASURE_MENTIONED_USER_REMOVED, // is no longer thrown
    VDERROR_BAD_REQUEST_NO_PRIVATE_MEASURE_PERMISSION,
    VDERROR_CONFLICT_USER_ALREADY_EXISTS,
    VDERROR_BAD_REQUEST_INVALID_DOMAIN,
    VDERROR_IDEA_MENTIONED_USER_REMOVED,
    VDERROR_NOT_FOUND_DEPARTMENT,
    VDERROR_NOT_FOUND_TREE_NODE,
    VDERROR_CONFLICT_CLIENT_ID,
    VDERROR_BAD_REQUEST_TREE_NODE_HAS_CHILDREN,
    VDERROR_BAD_REQUEST_TREE_NODE_IN_USE,
    VDERROR_NOT_FOUND_PERMISSION,
    VDERROR_INCORRECT_TFA_TOKEN,
    VDERROR_RATE_LIMIT_REACHED,
    VDERROR_BAD_REQUEST_CREATABLE_CUSTOM_FIELDS_NOT_TRANSLATABLE,
    VDERROR_BAD_REQUEST_FIELD_NOT_EXTENDABLE,
    VDERROR_NOT_FOUND_FEED_ENTRY,
    VDERROR_BAD_REQUEST_TREE_NODE_PARENT_ATTRIBUTE_MISMATCH,
    VDERROR_FORBIDDEN_ACCESS_PROCESS,
    VDERROR_BAD_FILTER_DEFINITION_PROBABILITY,
    VDERROR_FORBIDDEN_NO_PERMISSION,
    VDERROR_BAD_OPPORTUNITY_ID,
    VDERROR_RULE_ENGINE_ERROR,
    VDERROR_CONFLICT_ORDER_VALUES_NOT_UNIQUE,
    VDERROR_CONFLICT_PARENT_ID_NOT_UNIQUE,
    VDERROR_CONFLICT_LEVEL_LOCKED,
    VDERROR_CONFLICT_EFFECT_CATEGORIES_LOCKED,
    VDERROR_SAML_NO_SAML_RESPONSE,
    VDERROR_SAML_NO_ENTRY_POINT_IN_PROVIDER_CONFIG,
    VDERROR_SAML_NO_USER_IN_CALLBACK,
    VDERROR_GITHUB_NO_USER_IN_CALLBACK,
    VDERROR_GITHUB_NO_EMAIL_IN_PROFILE,
    VDERROR_NOT_FOUND_MEASURE_NOTIFICATION,
    VDERROR_INTERNAL_NO_AUTH_CONTEXT,
    VDERROR_NOT_FOUND_ACL_USER_ENTRY,
    VDERROR_NOT_FOUND_ACL_GROUP_ENTRY,
    VDERROR_NOT_FOUND_ATTRIBUTE_CATEGORY,
    VDERROR_ATTRIBUTE_CATEGORY_IN_USE,
    VDERROR_INVALID_FILTER_DEFINITION,
    VDERROR_CALCULATION_IDENTIFIER_EFFECTS_MISSING,
    VDERROR_BAD_REQUEST_VALUESTREAM_PERMISSION_MISSING,
    VDERROR_CONFLICT_USER_INVITE_STATUS_MISMATCH,
    VDERROR_INVALID_ATTRIBUTE_TYPE,
    VDERROR_NOT_FOUND_ASYNC_TASK,
    VDERROR_FORBIDDEN_CREATE_MEASURE,
    VDERROR_BAD_REQUEST_READONLY_FEATURE_FLAG_CHANGED,
    VDERROR_BAD_REQUEST_READONLY_RECURRING_EFFECT_DELETED,
}
