import { z } from "zod";
import { FiscalGranularity, PotentialType } from "../constants";
import { zFilterDefinition } from "./filters";
import { zScopeDto } from "./scope";

export const zProjectProgressBar = z.object({
    fiscalBucketIdentifier: z.string(),
    data: z.record(z.number().or(z.string())),
});
export type ProjectProgressBar = z.infer<typeof zProjectProgressBar>;

export const zProjectProgressBarListDto = z.array(zProjectProgressBar);
export type ProjectProgressBarListDto = z.infer<typeof zProjectProgressBarListDto>;

export const zProjectProgressScope = zScopeDto.extend({ startDate: z.string(), endDate: z.string() });
export type ProjectProgressScope = z.infer<typeof zProjectProgressScope>;

export const zProjectProgressRequestBody = z.object({
    filter: zFilterDefinition,
    scope: zProjectProgressScope,
    granularity: z.nativeEnum(FiscalGranularity),
    potentialType: z.nativeEnum(PotentialType),
});
export type ProjectProgressRequestBody = z.infer<typeof zProjectProgressRequestBody>;
