import { Operators } from "./Operators";

/**
 * These constants are used to reference global calculation identifiers. Make sure that these identifiers always exist globally in the
 * database. Use these constants sparingly and only if it is really necessary.
 */
export enum GlobalCalculationIdentifier {
    PrePlan = "prePlan",
    Plan = "plan",
    PostPlan = "postPlan",
    PreForecast = "preForecast",
    Forecast = "forecast",
    PostForecast = "postForecast",
    PreActual = "preActual",
    Actual = "actual",
    PostActual = "postActual",
}

export const CALCULATION_IDENTIFIERS_COMPLETED_ONLY = [
    GlobalCalculationIdentifier.PreActual,
    GlobalCalculationIdentifier.Actual,
    GlobalCalculationIdentifier.PostActual,
];

export enum EffectFilterDateField {
    StartDate = "startDate",
    EndDate = "endDate",
    EndOfRecurrence = "endOfRecurrence",
}

export enum EffectFilterCurrencyField {
    Initial = "initial",
    PriceHike = "priceHike",
    Target = "target",
    Effect = "effect",
}

export enum EffectField {
    Initial = "initial",
    PriceHike = "priceHike",
    Target = "target",
    Effect = "effect",
    StartDate = "startDate",
    EndDate = "endDate",
    HasInitial = "hasInitial",
    EffectType = "effectType",
}

export enum EffectFieldTypeModifier {
    Cumulated = "cumulated", // "RGB" potential
    Recurring = "recurring", // "R" potential
}

export enum PotentialType {
    Effect = "effect",
    Cumulated = "cumulatedEffect",
    Recurring = "recurringEffect",
}

export const CALCULATION_IDENTIFIER_MAPPING: Record<GlobalCalculationIdentifier, string> = {
    [GlobalCalculationIdentifier.PrePlan]: "pre_plan",
    [GlobalCalculationIdentifier.Plan]: "plan",
    [GlobalCalculationIdentifier.PostPlan]: "post_plan",
    [GlobalCalculationIdentifier.PreForecast]: "pre_forecast",
    [GlobalCalculationIdentifier.Forecast]: "forecast",
    [GlobalCalculationIdentifier.PostForecast]: "post_forecast",
    [GlobalCalculationIdentifier.PreActual]: "pre_actual",
    [GlobalCalculationIdentifier.Actual]: "actual",
    [GlobalCalculationIdentifier.PostActual]: "post_actual",
};

export const RELATIVE_DATE_OPERATOR_MAPPING = {
    [Operators.Before]: "<",
    [Operators.Within]: ">=",
    [Operators.InNext]: "<=",
    [Operators.InMore]: ">",
};

export const EFFECT_DB_COLUMN_MAP: Record<EffectFilterCurrencyField | EffectFilterDateField, string> = {
    [EffectFilterCurrencyField.Initial]: "initial",
    [EffectFilterCurrencyField.PriceHike]: "price_hike",
    [EffectFilterCurrencyField.Target]: "target",
    [EffectFilterCurrencyField.Effect]: "effect",
    [EffectFilterDateField.StartDate]: "start_date",
    [EffectFilterDateField.EndDate]: "end_date",
    [EffectFilterDateField.EndOfRecurrence]: "end_of_recurrence",
};

export const LATEST_PREFIX = "latest";

export type GlobalCalculationIdentifierOrLatest = GlobalCalculationIdentifier | typeof LATEST_PREFIX;

export enum EffectValueType {
    Calculated = "",
    Input = "input",
}

export enum CalculationEffectType {
    OneOff = 1,
    Baseline = 2,
    Recurring = 3,
}

export enum RecurringType {
    OneOff = 10,
    Recurring = 20,
}

export const PotentialTypeToCalculationTypes: Record<PotentialType, CalculationEffectType[]> = {
    [PotentialType.Effect]: [CalculationEffectType.OneOff, CalculationEffectType.Baseline],
    [PotentialType.Cumulated]: [CalculationEffectType.OneOff, CalculationEffectType.Baseline, CalculationEffectType.Recurring],
    [PotentialType.Recurring]: [CalculationEffectType.Recurring],
};
