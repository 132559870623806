import { Moment } from "moment";
import {
    EffectField,
    EffectFilterCurrencyField,
    EffectFilterDateField,
    EffectValueType,
    GlobalCalculationIdentifierOrLatest,
} from "../constants";

type CalendarMonth = {
    month: number;
    year: number;
};

export type MonthlyEffect = CalendarMonth & {
    value: number;
    inputValue: number;
};

// Map to field names type-safe to pascal case values, so type-safe index-access is possible
const EffectFilterCurrencyFieldMapping = {
    [EffectFilterCurrencyField.Effect]: "Effect",
    [EffectFilterCurrencyField.Initial]: "Initial",
    [EffectFilterCurrencyField.PriceHike]: "PriceHike",
    [EffectFilterCurrencyField.Target]: "Target",
    [EffectFilterDateField.StartDate]: "StartDate",
    [EffectFilterDateField.EndDate]: "EndDate",
    [EffectFilterDateField.EndOfRecurrence]: "EndOfRecurrence",
    [EffectField.HasInitial]: "HasInitial",
    [EffectField.EffectType]: "EffectType",
} as const;

const EffectValueTypeMapping = {
    [EffectValueType.Calculated]: "",
    [EffectValueType.Input]: "Input",
} as const;

export function buildEffectKey<
    TCalculationIdentifier extends GlobalCalculationIdentifierOrLatest,
    TField extends keyof typeof EffectFilterCurrencyFieldMapping,
    TInput extends keyof typeof EffectValueTypeMapping = EffectValueType.Calculated,
    TFormula extends "Formula" | "" = "",
>(
    calculationIdentifier: TCalculationIdentifier,
    name: TField,
    input?: TInput,
    formula?: TFormula,
): `${TCalculationIdentifier}${(typeof EffectValueTypeMapping)[TInput]}${(typeof EffectFilterCurrencyFieldMapping)[TField]}${TFormula}` {
    return `${calculationIdentifier}${EffectValueTypeMapping[input ?? EffectValueType.Calculated]}${EffectFilterCurrencyFieldMapping[name]}${formula ?? ""}` as any;
}

export const RECURRING_INTERVAL_MONTHS = 12;

export function areBaselineEffectsValid(firstBaselineMonth: Moment, baselineMonths: CalendarMonth[]): boolean {
    if (baselineMonths.length === 0) {
        return false;
    }

    const lastBaselineMoment = firstBaselineMonth.clone().add(RECURRING_INTERVAL_MONTHS - 1, "months");
    const upper = calendarMonthToNumber({ year: lastBaselineMoment.year(), month: lastBaselineMoment.month() });

    return !baselineMonths.some((baselineMonth) => calendarMonthToNumber(baselineMonth) > upper);
}

/**
 * This function calculates a single number of a given `CalendarMonth` object. These number can then be used to easily compare those objects by year and month.
 *
 *  **Important:** When using the calculated numbers for a comparison, make sure all compared objects use the same way to express months (0-index vs 1-index)!
 */
export function calendarMonthToNumber({ year, month }: CalendarMonth): number {
    return year * 100 + month;
}
