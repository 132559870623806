import { z } from "zod";
import { PotentialType } from "../constants";
import { zFilterDefinition } from "./filters";
import { zScopeDto } from "./scope";
import { zWaterfallBlockData } from "./widgets";

export const zWaterfallDataRequestBody = z.object({
    filter: zFilterDefinition,
    scope: zScopeDto,
    attribute: z.string().nullable(),
    fieldValues: z.array(z.union([z.string(), z.number()])),
    initialBlockName: z.string(),
    blocks: z.array(zWaterfallBlockData),
    potentialType: z.nativeEnum(PotentialType),
});
export type WaterfallDataRequestBody = z.infer<typeof zWaterfallDataRequestBody>;

export const zWaterfallDataRow = z.object({
    name: z.string(),
    value: z.number(),
    startValue: z.number(),
    content: z.object({
        attribute: z.string().nullable(),
        fieldValues: z.array(z.union([z.string(), z.number()])),
    }),
});
export type WaterfallDataRow = z.infer<typeof zWaterfallDataRow>;

export const zWaterfallData = z.array(zWaterfallDataRow);
export type WaterfallData = z.infer<typeof zWaterfallData>;
