import { z } from "zod";
import { CalculationType, EffectType, GlobalCalculationIdentifier, LATEST_PREFIX, RecurringType } from "../constants";
import { zNumericId } from "./baseschemas";
import { zEffectTotalValues } from "./effects";
import { zSpanEffectUpdateDto } from "./span-effects";

const zEffectCategoryValueDto = z.object({
    id: zNumericId,
    value: zNumericId,
    effectCategoryAttributeId: zNumericId,
});

export const zEffectCategoryDto = z.object({
    id: zNumericId,
    measureId: zNumericId,
    effectType: z.nativeEnum(EffectType),
    effectCategoryValues: z.array(zEffectCategoryValueDto).default([]),
    currencyId: zNumericId,
    recurringType: z.nativeEnum(RecurringType),
    aggregatedEffects: z.record(z.nativeEnum(GlobalCalculationIdentifier).or(z.literal(LATEST_PREFIX)), zEffectTotalValues.optional()),
    aggregatedInputEffects: z.record(z.nativeEnum(GlobalCalculationIdentifier).or(z.literal(LATEST_PREFIX)), zEffectTotalValues.optional()),
    calculationTypes: z.record(z.nativeEnum(GlobalCalculationIdentifier), z.nativeEnum(CalculationType)),
});

export type EffectCategoryDto = z.infer<typeof zEffectCategoryDto>;

export const zEffectCategoryListDto = z.array(zEffectCategoryDto);

export type EffectCategoryListDto = z.infer<typeof zEffectCategoryListDto>;

export const zEffectCategoryFields = z.record(z.number().optional());

export type EffectCategoryFields = z.infer<typeof zEffectCategoryFields>;

export const zCreateEffectCategoryDto = z.object({
    measureId: zNumericId,
    categoryFields: zEffectCategoryFields,
    calculationFields: zSpanEffectUpdateDto.omit({ calculationIdentifier: true }).optional(),
    effectType: z.nativeEnum(EffectType),
    currencyId: zNumericId,
});

export type CreateEffectCategoryDto = z.infer<typeof zCreateEffectCategoryDto>;

export const zUpdateEffectCategoryDto = z.object({
    categoryFields: zEffectCategoryFields.optional(),
    // effectType should not be updatable
    currencyId: zNumericId.optional(),
    recurringType: z.nativeEnum(RecurringType).optional(),
});

export type UpdateEffectCategoryDto = z.infer<typeof zUpdateEffectCategoryDto>;

export const zCopyEffectCategoryDto = z
    .object({
        measureId: zNumericId,
        targetCalculationIdentifier: z.nativeEnum(GlobalCalculationIdentifier),
    })
    .or(
        z.object({
            targetCalculationIdentifier: z.nativeEnum(GlobalCalculationIdentifier),
            effectCategoryId: zNumericId,
        }),
    );
export type CopyEffectCategoryDto = z.infer<typeof zCopyEffectCategoryDto>;
