import { z } from "zod";
import { MaxYear, MinYear } from "../constants";
import { zCompareExpression, zFilterDefinition } from "./filters";
import { zScopeDto } from "./scope";

export const zLegacyProjectProgressMonth = z.object({
    month: z.number().int().min(0).max(11),
    year: z.number().int().min(MinYear).max(MaxYear),
});

export type LegacyProjectProgressMonth = z.infer<typeof zLegacyProjectProgressMonth>;

const zLegacyProjectProgressScope = zScopeDto.omit({ startDate: true, endDate: true });

export type LegacyProjectProgressScope = z.infer<typeof zLegacyProjectProgressScope>;

export const zLegacyProjectProgressRequestBody = z.object({
    filter: zFilterDefinition,
    start: zLegacyProjectProgressMonth,
    end: zLegacyProjectProgressMonth,
    scope: zLegacyProjectProgressScope,
    oneTimeFieldValues: zCompareExpression,
    recurringFieldValues: zCompareExpression,
});

export type LegacyProjectProgressRequestBody = z.infer<typeof zLegacyProjectProgressRequestBody>;

const zLegacyProjectProgressRow = z.object({
    value: z.number(),
    timeRange: zLegacyProjectProgressMonth,
    stackProperty: z.string().min(1),
    stackValue: z.string().or(z.number()),
});
export type LegacyProjectProgressRow = z.infer<typeof zLegacyProjectProgressRow>;

export const zLegacyProjectProgress = z.array(zLegacyProjectProgressRow);
export type LegacyProjectProgressDto = z.infer<typeof zLegacyProjectProgress>;
