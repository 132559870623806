import moment, { Moment } from "moment";
import { FiscalGranularity, MeasureCalculationGranularity } from "../constants";
import { calendarMonthToNumber } from "./effects";
import { formatDateForAPI } from "./formatter";

export interface FiscalMoment {
    calendarMoment: Moment;
    /**
     * This is a shifted variant of calendarMoment, to account for the beginning of the fiscal year.
     *
     * Be careful when using this for calculations
     *
     * Mainly used for displaying reasons, e.g.:
     * - fiscal year: fiscalMoment.format("YYYY")
     * - fiscal quarter: fiscalMoment.format("Q")
     *
     * @type {Moment}
     * @memberof EffectColumn
     */
    fiscalMoment: Moment;
    fiscalYearStart: number;
}

export function generateFiscalMomentsFromInterval(
    start: Moment | null,
    end: Moment | null,
    granularity: MeasureCalculationGranularity,
    fiscalYearStart: number,
): FiscalMoment[] {
    if (start == null || end == null) {
        return [];
    }

    const effects: FiscalMoment[] = [];

    // map granularity to moment-understandable unit
    const IntervalFromGranularity = {
        [MeasureCalculationGranularity.MONTH]: "month" as const,
        [MeasureCalculationGranularity.FISCAL_QUARTER]: "quarter" as const,
        [MeasureCalculationGranularity.FISCAL_YEAR]: "year" as const,
    };
    const fiscalInterval = IntervalFromGranularity[granularity];

    // get the beginning of the fiscal unit, that contains effectStart
    const currentMoment = start.clone().subtract(fiscalYearStart, "months").startOf(fiscalInterval).add(fiscalYearStart, "months");

    while (currentMoment.isSameOrBefore(end)) {
        effects.push({
            fiscalYearStart,
            calendarMoment: currentMoment.clone(),
            fiscalMoment: currentMoment.clone().subtract(fiscalYearStart, "months"),
        });
        currentMoment.add(1, fiscalInterval);
    }

    return effects;
}

/**
 * Calculate the beginning and end of the fiscal year that the provided date is contained in.
 *
 * @param {Moment} date date for which the fiscal year should be computed
 * @param {number} offset months of year (0-11) in which a fiscal year starts
 *
 * @returns {[Moment, Moment]}
 */

export function getFiscalYearRange(date: Moment, offset: number): [Moment, Moment] {
    const fiscalYearStart = date.clone().startOf("month").subtract(offset, "months").startOf("year").add(offset, "months");
    const fiscalYearEnd = fiscalYearStart.clone().add(1, "year").subtract(1, "day").endOf("month");
    return [fiscalYearStart, fiscalYearEnd];
}

export class FiscalBucketIdentifier {
    constructor(
        public readonly year: number,
        public readonly month: number,
    ) {}

    static fromString(input: string): FiscalBucketIdentifier {
        const identifier = moment.utc(input);

        return new FiscalBucketIdentifier(identifier.year(), identifier.month());
    }

    toString(): string {
        const identifier = moment.utc({ year: this.year, month: this.month, day: 1 });
        return formatDateForAPI(identifier);
    }

    public toUtcMoment(): moment.Moment {
        return moment.utc({ ...this, day: 1 });
    }
}

export interface FiscalBucket {
    bucketIdentifier: FiscalBucketIdentifier;
    lowerBoundary: number;
    upperBoundary: number;
}

/**
 * This function creates a list of buckets (fiscal years, fiscal quarters, months) with an upper and lower boundary that the given date range touches.
 * The upper and lower boundary are comparable numbers generated by `calendarMonthToNumber` from the year and month of the first and last day of the bucket.
 * This function will account for a shifted fiscal year.
 *
 * **Important:** The bucket boundaries returned will assume 0-index for months. Also the parameter `fiscalYearStart` is 0-indexed.
 *
 * @example
 * If the given date range is 2023-01-01 to 2024-12-31 then the function will return the following for granularity "year":
 * ```ts
 * [
 * {
 *   bucketIdentifier: FiscalBucketIdentifier(2023, 0),
 *   upperBoundary: 202300,
 *   lowerBoundary: 202311
 * },
 * {
 *   bucketIdentifier: FiscalBucketIdentifier(2024, 0),
 *   upperBoundary: 202400,
 *   lowerBoundary: 202411
 * }
 * ]
 * ```
 *
 * @example
 * If the given date range is 2023-01-01 to 2024-12-31 with a fiscal year start in month 2 (=march) then the function will return the following for granularity "year":
 * ```ts
 * [
 * {
 *   bucketIdentifier: FiscalBucketIdentifier(2022, 2),
 *   upperBoundary: 202202,
 *   lowerBoundary: 202301
 * },
 * {
 *   bucketIdentifier: FiscalBucketIdentifier(2023, 2),
 *   upperBoundary: 202302,
 *   lowerBoundary: 202401
 * },
 * {
 *   bucketIdentifier: FiscalBucketIdentifier(2024, 2),
 *   upperBoundary: 202402,
 *   lowerBoundary: 202501
 * }
 * ]
 * ```
 */
export function generateFiscalBucketsFromInterval(
    startDate: string | null,
    endDate: string | null,
    fiscalYearStart: number,
    granularity: FiscalGranularity,
): FiscalBucket[] {
    if (startDate == null || endDate == null) {
        return [];
    }
    const start = moment.utc(startDate);
    const end = moment.utc(endDate);

    const fiscalMoments = generateFiscalMomentsFromInterval(start, end, mapGranularity(granularity), fiscalYearStart);

    return fiscalMoments.map((fiscalMoment) => {
        const currentMoment = { year: fiscalMoment.calendarMoment.year(), month: fiscalMoment.calendarMoment.month() };
        const endOfMoment = fiscalMoment.calendarMoment.clone().add(1, granularity).subtract(1, "day").endOf("month");

        return {
            bucketIdentifier: new FiscalBucketIdentifier(currentMoment.year, currentMoment.month),
            lowerBoundary: calendarMonthToNumber(currentMoment),
            upperBoundary: calendarMonthToNumber({ year: endOfMoment.year(), month: endOfMoment.month() }),
        };
    });
}

function mapGranularity(granularity: FiscalGranularity): MeasureCalculationGranularity {
    switch (granularity) {
        case FiscalGranularity.Year:
            return MeasureCalculationGranularity.FISCAL_YEAR;
        case FiscalGranularity.Quarter:
            return MeasureCalculationGranularity.FISCAL_QUARTER;
        case FiscalGranularity.Month:
            return MeasureCalculationGranularity.MONTH;
    }
}
