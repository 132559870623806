export enum FeatureFlags {
    FEATURE_METHOD_SECTION = "methodSection",
    FEATURE_EXTENDED_METHOD_SEARCH = "extendedMethodSearch",
    FEATURE_ACTIVITY_TEMPLATES = "activityTemplates",
    FEATURE_EXTENDED_CALCULATION = "extendedCalculation",
    FEATURE_RESTRICTED_EFFECTIVENESS_COMPLETION = "restrictedEffectivenessCompletion",
    FEATURE_IDEA_SECTION = "ideaSection",
    FEATURE_CONTRIBUTOR_INVITE_BY_USER = "contributorInviteByUser",
    FEATURE_INTEGRATIONS = "integrations",
    FEATURE_EFFORT_ESTIMATION = "effortEstimation",
    FEATURE_OPENAI_API = "openaiApi",
    FEATURE_EXPECTED_PRICE_INCREASE = "expectedPriceIncrease",
    FEATURE_PROCESS_SELF_DECISION = "selfDecision",
    FEATURE_EXCEL_IMPORT = "excelImport",
    FEATURE_WHITE_SPOT_MATRIX = "whiteSpotMatrix",
    FEATURE_LIVE_RUN_UP = "liveRunUp",
    FEATURE_C_LEVEL_DASHBOARD_WIDGETS = "cLevelDashboardWidgets",
    FEATURE_MANDATORY_LEVEL_DATES = "mandatoryLevelDates",
    FEATURE_RECURRING_EFFECTS = "recurringEffects",

    // For all those nice things we want to mess around with but that shouldn't be customer facing
    FEATURE_EXPERIMENTAL = "experimental",
}
