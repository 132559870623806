import { z } from "zod";
import { AsyncTaskStatus } from "../../constants";
import { zNumericId } from "../baseschemas";

export const zSuperAdminMigratedCustomFieldRequestBody = z.object({
    clientId: zNumericId,
    attributeId: zNumericId,
});
export type SuperAdminMigratedCustomFieldRequestBody = z.infer<typeof zSuperAdminMigratedCustomFieldRequestBody>;

export const zSuperAdminMigratedCustomFieldResponse = z.object({
    taskId: zNumericId,
});
export type SuperAdminMigratedCustomFieldDto = z.infer<typeof zSuperAdminMigratedCustomFieldResponse>;

export const zSuperAdminAsyncTaskDto = z.object({
    id: zNumericId,
    status: z.nativeEnum(AsyncTaskStatus),
    progress: z.number(),
    result: z.record(z.unknown()).nullable(),
});
export type SuperAdminAsyncTaskDto = z.infer<typeof zSuperAdminAsyncTaskDto>;
