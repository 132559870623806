import { z } from "zod";
import { PotentialType } from "../constants";
import { zFilterDefinition } from "./filters";

export const zMeasureTimelineRequestBody = z.object({
    timeFrame: z.number().int(),
    filter: zFilterDefinition,
    fiscalYear: z.number().int(),
    potentialType: z.nativeEnum(PotentialType),
});

export type MeasureTimelineRequestBody = z.infer<typeof zMeasureTimelineRequestBody>;

const zTimelineRow = z.object({
    week: z.number(),
    carryOver: z.number().optional(),
    sums: z.record(z.number()),
});
export type TimelineRow = z.infer<typeof zTimelineRow>;

export const zTimeline = z.array(zTimelineRow);
export type TimelineDto = z.infer<typeof zTimeline>;
