import { z } from "zod";
import { AggregationMethod, GlobalCalculationIdentifier, PivotMetric, PotentialType } from "../constants";
import { zFilterDefinition } from "./filters";
import { zScopeDto } from "./scope";

export const zPivotRowDto = z.object({
    fields: z.record(z.unknown()),
    value: z.number(),
});

export type PivotRowDto = z.infer<typeof zPivotRowDto>;

export const zPivotResponseDto = z.object({
    data: z.array(zPivotRowDto),
});

export type PivotResponseDto = z.infer<typeof zPivotResponseDto>;

export const zPivotRequestBody = z.object({
    aggregation: z.nativeEnum(AggregationMethod),
    metric: z.nativeEnum(PivotMetric),
    fields: z.string().array().min(1),
    filter: zFilterDefinition.optional(),
    scope: zScopeDto.optional(),
});

export type PivotRequestBody = z.infer<typeof zPivotRequestBody>;

export const zReportingCompletedEffectDto = z
    .object({
        snapshot: z.string(),
        effect: z.number().optional(),
    })
    // catchall so that any other, unknown keys are allowed and validated as with string | number
    .catchall(z.string().or(z.number()));

export const zReportingCompletedEffectsDto = z.object({
    data: z.array(zReportingCompletedEffectDto),
});

export type ReportingCompletedEffectsDto = z.infer<typeof zReportingCompletedEffectsDto>;

export const zRollingForecastDto = z.object({
    month: z.number(),
    year: z.number(),
    effects: z.record(z.string(), z.number()),
    rollingEffects: z.record(z.string(), z.number()).optional(),
});
export type RollingForecastDto = z.infer<typeof zRollingForecastDto>;

export const zRollingForecastListDto = z.array(zRollingForecastDto);

export type RollingForecastListDto = z.infer<typeof zRollingForecastListDto>;

export const zRollingForecastRequestBody = z.object({
    filter: zFilterDefinition,
    scope: zScopeDto.extend({ startDate: z.string(), endDate: z.string() }),
    calculationIdentifiers: z.array(z.nativeEnum(GlobalCalculationIdentifier)),
    includeCombinedACT: z.boolean(),
    potentialType: z.nativeEnum(PotentialType),
});
export type RollingForecastRequestBody = z.infer<typeof zRollingForecastRequestBody>;
